<template>
  <div
    v-loading="loading"
    element-loading-text="拼命加载中"
    class="knowledge-box"
  >
    <div class="knowledge-title">
      <span>薄弱知识点</span>
      <div class="btn-list-box">
        <div class="knowledge-top">
          <template v-for="(v, i) in menuList">
            <div :key="i" :class="{ on: v.isClick }" @click="setMenu(i)">
              {{ v.label }}
            </div>
          </template>
        </div>
        <el-input
          v-if="menuList[0].isClick"
          v-model="page.knowledgeName"
          placeholder="请输入内容"
          class="input-with-select"
          @keyup.enter.native="search"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          ></el-button>
        </el-input>
      </div>
      <!-- {{ childObj.levelId }} -->
      <div class="right">
        <!-- <el-select
          v-if="childObj.studentId"
          v-model="subjectOptionsVal"
          placeholder="请选择"
          @change="setComType"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <el-button
          type="primary"
          class="btn-all"
          :loading="btnloading"
          @click="knowledgeExport"
          >导出</el-button
        >
      </div>
    </div>

    <div>
      <!-- <el-checkbox-group v-model="checkList"> -->
      <!-- <el-checkbox label="1">对比：区域得分率</el-checkbox> -->
      <!-- <el-checkbox label="2"
          >对比：
          <template v-if="childObj.studentId">学生得分率</template>
          <template v-if="childObj.classNum && !childObj.studentId"
            >班级得分率</template
          >
          <template v-if="!childObj.classNum && !childObj.studentId"
            >年级得分率</template
          >
        </el-checkbox>
      </el-checkbox-group> -->
    </div>
    <div>
      <el-table :data="pageList" style="width: 100%">
        <el-table-column :key="key" align="center" width="180">
          <template slot="header">
            <div class="table-header">
              <el-checkbox
                v-if="showCheck"
                v-model="knowChecked"
                @change="checkAll()"
              ></el-checkbox>
              <span class="title">目录</span>
              <el-select v-model="filtersValue" placeholder="请选择">
                <el-option
                  v-for="item in filtersList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="know-check">
              <el-checkbox-group v-if="showCheck" v-model="knowCheckList">
                <el-checkbox :label="scope.row.knowledgeId"></el-checkbox>
              </el-checkbox-group>
              <template v-if="scope.row.knowledgeId.length - 2 == 1"
                >一级</template
              >
              <template v-if="scope.row.knowledgeId.length - 2 == 2"
                >二级</template
              >
              <template v-if="scope.row.knowledgeId.length - 2 == 3"
                >三级</template
              >
              <template v-if="scope.row.knowledgeId.length - 2 == 4"
                >四级</template
              >
              <template v-if="scope.row.knowledgeId.length - 2 == 5"
                >五级</template
              >
              <template v-if="scope.row.knowledgeId.length - 2 == 6"
                >六级</template
              >
            </div>
            <!-- <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >Edit</el-button
            > -->
          </template>
        </el-table-column>
        <!-- <el-table-column prop="knowledgeName" label="知识点"></el-table-column> -->
        <el-table-column :key="newKeys + 'new'" align="center">
          <template slot="header">
            <div class="table-header">
              <span class="title">知识点</span>
              <span
                v-if="menuList[1].isClick"
                class="show-model"
                @click="dialogVisible = true"
                >筛选<i class="el-icon-question"></i
              ></span>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.knowledgeName }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="childObj.level > 1"
          :key="sortKey + 'knowledgeFreq'"
          width="100"
          prop="knowledgeFreq"
          label="高考频率"
          align="center"
        >
          <template slot="header">
            <div class="sort-header">
              <span v-if="childObj.level == 3" class="title">高考频率</span>
              <span v-else class="title">中考频率</span>
              <div>
                <i
                  class="el-icon-caret-top"
                  :class="{ 'on-sort': sortType == 1 && sortOrder == 1 }"
                  @click="setSort(1, 1)"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ 'on-sort': sortType == 1 && sortOrder == 2 }"
                  @click="setSort(1, 2)"
                ></i>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <template v-if="scope.row.knowledgeFreq">{{
              scope.row.knowledgeFreq
            }}</template>
            <template v-else> 无 </template>
          </template>
        </el-table-column>
        <el-table-column
          :key="sortKey + 'frequency'"
          align="center"
          width="100"
        >
          <template slot="header">
            <div class="sort-header">
              <span v-if="!childObj.classNum" class="title">年级考频</span>
              <span
                v-if="childObj.classNum && !childObj.studentId"
                class="title"
              >
                班级考频
              </span>
              <span
                v-if="childObj.classNum && childObj.studentId"
                class="title"
              >
                个人考频
              </span>
              <div>
                <i
                  class="el-icon-caret-top"
                  :class="{ 'on-sort': sortType == 2 && sortOrder == 1 }"
                  @click="setSort(2, 1)"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ 'on-sort': sortType == 2 && sortOrder == 2 }"
                  @click="setSort(2, 2)"
                ></i>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.frequency }}
          </template>
        </el-table-column>
        <el-table-column
          :key="sortKey + 'scoreRateAvg'"
          align="center"
          width="120"
        >
          <template slot="header">
            <div class="sort-header">
              <span v-if="!childObj.classNum" class="title">年级得分率</span>
              <span
                v-if="childObj.classNum && !childObj.studentId"
                class="title"
              >
                班级得分率
              </span>
              <span
                v-if="childObj.classNum && childObj.studentId"
                class="title"
              >
                个人得分率
              </span>
              <div>
                <i
                  class="el-icon-caret-top"
                  :class="{ 'on-sort': sortType == 3 && sortOrder == 1 }"
                  @click="setSort(3, 1)"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="{ 'on-sort': sortType == 3 && sortOrder == 2 }"
                  @click="setSort(3, 2)"
                ></i>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            {{ scope.row.scoreRateAvg | setNumber }}
          </template>
        </el-table-column>

        <el-table-column v-if="!childObj.classNum" label="年级" align="center">
          <el-table-column
            :key="sortKey + 'scoreRateTop27'"
            prop="scoreRateTop27"
            align="center"
          >
            <template slot="header">
              <div class="sort-header">
                <span class="title">前27%群体得分率</span>
                <div>
                  <i
                    class="el-icon-caret-top"
                    :class="{ 'on-sort': sortType == 4 && sortOrder == 1 }"
                    @click="setSort(4, 1)"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="{ 'on-sort': sortType == 4 && sortOrder == 2 }"
                    @click="setSort(4, 2)"
                  ></i>
                </div>
              </div>
            </template>
            <template slot-scope="scope">{{
              scope.row.scoreRateTop27 | setNumber
            }}</template>
          </el-table-column>
          <el-table-column
            :key="sortKey + 'scoreRateLast27'"
            prop="scoreRateLast27"
            align="center"
          >
            <template slot="header">
              <div class="sort-header">
                <span class="title">后27%群体得分率</span>
                <div>
                  <i
                    class="el-icon-caret-top"
                    :class="{ 'on-sort': sortType == 5 && sortOrder == 1 }"
                    @click="setSort(5, 1)"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="{ 'on-sort': sortType == 5 && sortOrder == 2 }"
                    @click="setSort(5, 2)"
                  ></i>
                </div>
              </div>
            </template>
            <template slot-scope="scope">{{
              scope.row.scoreRateLast27 | setNumber
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-else label="班级" align="center">
          <el-table-column
            :key="sortKey + 'scoreRateTop27'"
            prop="scoreRateTop27"
            align="center"
          >
            <template slot="header">
              <div class="sort-header">
                <span class="title">前27%群体得分率</span>
                <div>
                  <i
                    class="el-icon-caret-top"
                    :class="{ 'on-sort': sortType == 4 && sortOrder == 1 }"
                    @click="setSort(4, 1)"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="{ 'on-sort': sortType == 4 && sortOrder == 2 }"
                    @click="setSort(4, 2)"
                  ></i>
                </div>
              </div>
            </template>
            <template slot-scope="scope">{{
              scope.row.scoreRateTop27 | setNumber
            }}</template>
          </el-table-column>
          <el-table-column
            :key="sortKey + 'scoreRateLast27'"
            prop="scoreRateLast27"
            align="center"
          >
            <template slot="header">
              <div class="sort-header">
                <span class="title">后27%群体得分率</span>
                <div>
                  <i
                    class="el-icon-caret-top"
                    :class="{ 'on-sort': sortType == 5 && sortOrder == 1 }"
                    @click="setSort(5, 1)"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="{ 'on-sort': sortType == 5 && sortOrder == 2 }"
                    @click="setSort(5, 2)"
                  ></i>
                </div>
              </div>
            </template>
            <template slot-scope="scope">{{
              scope.row.scoreRateLast27 | setNumber
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="date" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" class="btn-color" @click="show(scope.row)">
              查看更多 >>
            </el-button>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="date" label="日期"></el-table-column> -->
      </el-table>
      <!-- {{ page.current }} -->
      <div class="page-box">
        <div class="page-left-box">
          <el-button
            v-if="showCheck == false"
            type="primary"
            class="btn-all"
            @click="showCheckChange"
            >选择薄弱知识点</el-button
          >
          <template v-else>
            <el-button type="text" @click="showCheckChange"
              >关闭知识点筛选</el-button
            >
            <p class="intro">
              已选<span>{{ knowCheckList.length }}</span
              >项知识点
            </p>
            <el-button
              type="primary"
              class="btn-all"
              :disabled="knowCheckList.length == 0"
              @click="toKnowList"
              >去选题<i class="el-icon-d-arrow-right el-icon--right"></i
            ></el-button>
          </template>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :pages="pages"
          :current-page="page.current"
          @current-change="clickList"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog title="请选择章节" :visible.sync="dialogVisible" width="30%">
      <div>
        <el-cascader
          v-model="value"
          :options="geteditionlistData"
          @change="handleChange"
        ></el-cascader>
        <div class="tree-box more-tree">
          <el-tree
            ref="tree"
            :data="getbookchapterList"
            node-key="number"
            :props="defaultProps"
            :filter-node-method="filterNode"
            :default-checked-keys="loreTureArrid"
            :show-checkbox="true"
            @check="elTreeChange"
          >
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="showList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  analystatgradePage,
  knowledgeExport,
} from "@/core/api/analy/gradeLearning";
import {
  getbookchapterbyid,
  getbooklistbylevelandsubject,
} from "@/core/api/resource/knowledgePointsPaper";
import { fileDownloadByUrl } from "@/core/util/util";
import { subjectOptions } from "@/core/util/constdata";
export default {
  name: "KnowledgeBox",
  filters: {
    setNumber(val) {
      if (!val) {
        return "0.00 %";
      }
      let number = (val * 100).toFixed(2);
      return number + " %";
    },
  },
  props: {
    childObj: Object,
    subjectId: Number,
  },
  data() {
    return {
      knowChecked: false,
      showCheck: false,
      knowledgeList: [],
      knowCheckList: [],
      // 知识点选择
      subjectOptionsVal: 2,
      subjectOptions: subjectOptions,
      loading: true,
      dialogVisible: false,
      btnloading: false,
      menuList: [
        { label: "按知识点", isClick: true },
        { label: "按章节", isClick: false },
      ],
      filtersValue: 0,
      filtersList: [
        { label: "全部", value: 0 },
        { label: "一级", value: 1 },
        { label: "二级", value: 2 },
        { label: "三级", value: 3 },
        { label: "四级", value: 4 },
        { label: "五级", value: 5 },
        { label: "六级", value: 6 },
      ],
      page: {
        current: 1,
        size: 10,
        knowledgeLevel: 0,
        knowledgeName: "",
      },
      total: 0,
      pages: 0,
      checkList: ["1", "2"],
      pageList: [],
      key: 1,
      newKeys: 1,
      sortKey: 1,
      geteditionlistData: [],
      getbookchapterList: [],
      value: [],
      loreTureArrid: [],
      defaultProps: {
        children: "child",
        label: "name",
      },
      sortType: 3,
      sortOrder: 1,
      oldSubjectId: 0,
    };
  },
  watch: {
    filtersValue() {
      this.key = new Date().getTime();
      this.page.current = 1;
      this.page.knowledgeLevel = this.filtersValue;
      this.clearKnowIds();
      this.getList();
    },
    subjectId() {
      if (this.subjectId) {
        this.subjectOptionsVal = this.subjectId;
        this.page.current = 1;
        this.geteditionlist();
        this.getList();
      }
    },
    pageList: {
      deep: true,
      handler() {
        //判断下当前有多少项在已选列表中  如果所有都在  就把全选勾上
        let index = 0;
        this.pageList.forEach((item) => {
          if (this.knowCheckList.indexOf(item.knowledgeId) != -1) {
            index++;
          }
        });
        if (index == this.pageList.length) {
          this.knowChecked = true;
          this.key = new Date().getTime();
        }
        if (this.pageList.length == 0) {
          this.knowChecked = false;
          this.key = new Date().getTime();
        }
        // this.knowCheckList
      },
    },
    knowCheckList: {
      deep: true,
      handler() {
        // this.knowCheckList = [...new Set(this.knowCheckList)];
        // console.log(this.knowCheckList);
        let pageArr = [];
        this.pageList.forEach((item) => {
          pageArr.push(item.knowledgeId);
          if (this.knowCheckList.indexOf(item.knowledgeId) != -1) {
            this.knowledgeList.push({
              knowledgeId: item.knowledgeId,
              knowledgeName: item.knowledgeName,
              scoreRateAvg: item.scoreRateAvg,
            });
          }
        });
        // this.knowledgeList = [...new Set(this.knowledgeList)];
        this.knowledgeList = this.knowledgeList.reduce(function (
          prev,
          element
        ) {
          if (!prev.find((el) => el.knowledgeId == element.knowledgeId)) {
            prev.push(element);
          }
          return prev;
        },
        []);
        // console.log(newArray);
        // console.log(this.knowCheckList);
        //如果页面列表存在id，但是在选中项中没有id   就说明没有选中  剔除
        // let newArray = [];
        this.knowledgeList.forEach((item) => {
          if (
            pageArr.indexOf(item.knowledgeId) != -1 &&
            this.knowCheckList.indexOf(item.knowledgeId) == -1
          ) {
            this.knowledgeList = this.knowledgeList.filter(
              (index) => index.knowledgeId != item.knowledgeId
            );
          }
        });
        // console.log(this.knowledgeList);

        // console.log(this.knowledgeList);
      },
    },
    childObj: {
      immediate: true,
      handler() {
        this.loading = true;
        // console.log(this.oldSubjectId);
        // console.log(this.childObj.subjectId);
        if (this.oldSubjectId != this.childObj.subjectId) {
          this.menuList[0].isClick = true;
          this.menuList[1].isClick = false;
          this.loreTureArrid = [];
          this.oldSubjectId = this.childObj.subjectId;
        }
        this.clearKnowIds();
        // console.log(this.childObj);
        this.page.current = 1;
        this.geteditionlist();
        this.getList();
      },
    },
  },
  created() {
    // this.geteditionlist();
    // this.getList();
    this.oldSubjectId = this.childObj.subjectId;
  },
  methods: {
    toKnowList() {
      // if(this.knowledgeList.le)
      let data = {
        val: this.knowledgeList,
        subjectId: this.childObj.subjectId,
        levelId: this.childObj.level,
      };
      // console.log(data);
      this.$store.commit("setSubjectKnow", data);
      this.$router.push({
        name: "WeakTestPaper",
      });
    },
    showCheckChange() {
      // console.log(this.showCheck);
      if (this.showCheck) {
        this.showCheck = false;
      } else {
        this.showCheck = true;
      }
      // this.knowCheckList = [...new Set(this.knowCheckList)];
      // this.knowCheckList = [...new Set(this.knowCheckList)];
      // console.log(this.showCheck);
      // this.showCheck = this.showCheck == true ? false : true;
      this.key = new Date().getTime();
    },
    clearKnowIds() {
      this.key = new Date().getTime();
      this.knowChecked = false;
      this.knowCheckList = [];
    },
    //设置 key
    checkAll() {
      this.key = new Date().getTime();
      // console.log(this.knowChecked);
      // console.log(this.knowCheckList);
      if (this.knowChecked) {
        this.pageList.forEach((item) => {
          this.knowCheckList.push(item.knowledgeId);
        });
      } else {
        this.pageList.forEach((item) => {
          let indexOfKnow = this.knowCheckList.indexOf(item.knowledgeId);
          if (indexOfKnow != -1) {
            this.knowCheckList.splice(indexOfKnow, 1);
          }
        });
      }
      this.knowCheckList = [...new Set(this.knowCheckList)];
    },
    // type当前排序类型 ，index排序方式
    setSort(type, index) {
      this.sortType = type;
      this.sortOrder = index;
      this.sortKey = new Date().getTime();
      this.page.current = 1;
      this.getList();
      // console.log(type);
      // console.log(index);
    },
    showList() {
      this.dialogVisible = false;
      this.page.current = 1;
      this.loading = true;
      this.clearKnowIds();
      this.getList();
    },
    elTreeChange() {
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
    },
    filterNode(value, data) {
      // console.log(value, data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleChange(val) {
      if (val.length < 2) {
        return;
      }
      this.getbookchapterbyid(val[1]);
    },
    // /通过科目、学段查询教材列表列表
    geteditionlist() {
      // console.log(this.childObj.subjectId);
      // console.log(this.subjectId);
      // console.log(!this.childObj.subjectId && !this.subjectId);
      if (!this.childObj.subjectId && !this.subjectOptionsVal) {
        return;
      }
      getbooklistbylevelandsubject({
        levelId: this.childObj.level,
        subjectId: this.childObj.subjectId || this.subjectOptionsVal,
      }).then((res) => {
        this.geteditionlistData = [];
        let array = [];
        res.data.data.forEach((index) => {
          if (array.length == 0 || array.indexOf(index.editionId) == -1) {
            array.push(index.editionId);
            this.geteditionlistData.push({
              label: index.editionName,
              value: index.editionId,
              children: [],
            });
          }
          if (array.indexOf[index.editionId] != -1) {
            this.geteditionlistData.forEach((item) => {
              if (item.value == index.editionId) {
                item.children.push({
                  label:
                    index.gradeName +
                    index.editionName +
                    index.termName +
                    index.name,
                  value: index.id,
                });
              }
            });
          }
        });
        if (this.geteditionlistData.length > 0) {
          this.value = [];
          this.value.push(this.geteditionlistData[0].value);
          this.value.push(this.geteditionlistData[0].children[0].value);
          this.getbookchapterbyid(this.value[1]);
        }

        // console.log(this.geteditionlistData);
      });
    },
    // 通过id获取章节
    getbookchapterbyid(editionId) {
      getbookchapterbyid(editionId).then((res) => {
        // console.log(res);
        this.getbookchapterList = [];

        this.getbookchapterData = res.data.data;
        let arrList = this.getbookchapterData;
        // 递归生成一个新树形节点
        // console.log(arrList.filter(i => i.rank == 1 && i.name != "听力"));
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.getbookchapterList.push(obj);
          });
      });
    },
    search() {
      this.page.current = 1;
      this.loading = true;
      this.clearKnowIds();
      this.getList();
    },
    //递归形成树形结构
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      // console.log(result);
      return result;
    },
    setComType() {
      this.loading = true;
      this.page.current = 1;
      this.$emit("setId", this.subjectOptionsVal);
      this.getList();
    },
    knowledgeExport() {
      let data = JSON.parse(JSON.stringify(this.childObj));
      if (!data.subjectId) {
        data.subjectId = this.subjectOptionsVal;
      }
      data.knowledgeLevel = this.filtersValue;
      delete data.current;
      delete data.size;
      this.btnloading = true;
      data.sortType = this.sortType;
      data.sortOrder = this.sortOrder;
      knowledgeExport(data)
        .then((res) => {
          // console.log(res.data);
          this.btnloading = false;
          if (res.data.data) {
            fileDownloadByUrl(res.data.data);
          }
        })
        .catch(() => {
          this.btnloading = false;
        });
    },
    setMenu(i) {
      this.menuList.forEach((index) => {
        index.isClick = false;
      });
      this.menuList[i].isClick = true;
      this.newKeys = new Date().getTime();
      if (i == 1) {
        this.dialogVisible = true;
      } else {
        this.page.current = 1;
        this.getList();
      }
    },
    clickList(i) {
      this.knowChecked = false;
      this.key = new Date().getTime();
      this.page.current = i;
      this.getList();
    },
    show(val) {
      // console.log(val);
      let data = JSON.parse(JSON.stringify(this.childObj));
      data.knowledgeId = val.knowledgeId;
      data.knowledgeName = val.knowledgeName;
      data.urlType = this.$route.name;
      delete data.current;
      delete data.size;
      this.$router.push({
        name: "ShowKnowledge",
        query: data,
      });
    },
    getList() {
      let data;

      // let data1 = { subjectId: 1, timeRange: 0, gradeId: 132, class_num: 7 };
      let dataChildObj = JSON.parse(JSON.stringify(this.childObj));

      // if (dataChildObj.studentId) {
      //   data = Object.assign(dataChildObj, this.page, {
      //     subjectId: this.subjectOptionsVal
      //   });
      // } else {
      data = Object.assign(dataChildObj, this.page);
      // }
      if (this.menuList[1].isClick) {
        let arrTureArrid = this.loreTureArrid;
        // 去除存在子集的id
        this.getbookchapterData.forEach((i) => {
          arrTureArrid.forEach((item, index) => {
            if (i.number == item && i.child.length > 0) {
              arrTureArrid.splice(index, 1);
            }
          });
        });
        if (arrTureArrid.length > 0) {
          data.chapterIds = arrTureArrid.join(",");
        } else {
          data.chapterIds = "";
        }
      } else {
        data.chapterIds = "";
      }
      // this.sortType = type;
      // this.sortOrder = index;
      this.loading = true;
      data.sortType = this.sortType;
      data.sortOrder = this.sortOrder;
      analystatgradePage(data)
        .then((res) => {
          // console.log(res);
          this.pageList = res.data.data.records;
          this.total = res.data.data.total;
          this.pages = res.data.data.pages;
          this.loading = false;
          if (this.page.current == 1) {
            if (this.knowCheckList.length == 0) {
              // this.pageList.forEach((item, index) => {
              // if (index < 5) {
              //   this.knowCheckList.push(item.knowledgeId);
              // }
              // });
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.knowledge-box {
  .el-table td,
  .el-table th {
    padding: 8px 0;
    .know-check {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 14px;
      font-size: 14px;
      .el-checkbox-group {
        font-size: 14px;
        margin-right: 5px;
      }
    }
    .cell {
      .el-checkbox {
        margin-right: 5px;
        margin-bottom: 0;
        .el-checkbox__label {
          display: none;
        }
      }
    }
    // .table-header {
    //   .el-checkbox {
    //     margin-right: 5px;
    //     margin-bottom: 0;
    //   }
    // }
  }
  .table-header {
    .el-select {
      .el-input__inner {
        height: 24px;
        line-height: 24px;
      }

      .el-select__caret {
        height: 24px;
        line-height: 24px;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.knowledge-box {
  border: 1px solid #dddddd;
  padding: 100px 24px 24px;
  position: relative;
  .sort-header {
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      line-height: 16px;
      .on-sort {
        color: $primary-color;
      }
      i {
        line-height: 0.6;
        font-size: 16px;
        display: block;
        cursor: pointer;
      }
    }
  }
  .table-header {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-select {
      width: 80px;
      margin-left: 6px;
    }
    .show-model {
      cursor: pointer;
      color: $primary-color;
      margin-left: 6px;
    }
  }
  .btn-color {
    color: $primary-color;
  }
  .el-select {
    width: 180px;
    margin-right: 12px;
  }
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    .page-left-box {
      display: flex;
      align-items: center;
      .el-button--text {
        color: $primary-color;
      }
      .intro {
        margin: 0 12px;
        span {
          color: $primary-color;
        }
      }
    }
    .el-pagination {
      padding-right: 0px;
    }
  }
  .knowledge-top {
    display: flex;
    justify-content: center;
    // margin: 12px 0 12px 20px;
    margin-right: 12px;

    div {
      padding: 6px 12px;
      border: 1px solid $primary-color;
      cursor: pointer;
      font-size: 14px;
    }
    .on {
      background-color: $primary-color;
      color: #ffffff;
    }
  }
  .knowledge-title {
    position: absolute;
    left: 0;
    top: 24px;
    padding: 0 24px;
    color: $primary-color;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .btn-list-box {
      display: flex;
      align-items: center;
    }
    .input-with-select {
      width: 300px;
    }
    .el-button {
      padding: 6px 12px;
    }
    &::after {
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: $primary-color;
      content: "";
      left: 0;
      top: 0;
    }
  }
}
</style>
