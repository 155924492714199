<template>
  <div v-loading="loading" element-loading-text="拼命加载中" class="wrong-box">
    <div class="wrong-title">
      <span>所有试题</span>
      <!-- <span v-if="childObj.classNum && !childObj.studentId">所有错题</span>
      <span v-if="childObj.classNum && childObj.studentId">个人错题</span> -->
      <div>
        <!-- <el-select
          v-if="childObj.studentId"
          v-model="subjectOptionsVal"
          placeholder="请选择"
          @change="setComType"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <el-checkbox
          v-model="isLatestDevelopment"
          :true-label="1"
          :false-label="0"
          style="margin-right: 18px"
          @change="getIsLast()"
        >
          只看最近发展区试题
        </el-checkbox>
        <el-button
          type="primary"
          class="btn-all"
          :loading="btnloading"
          @click="questionExport"
          >一键导出</el-button
        >
      </div>

      <!-- questionExport -->
    </div>
    <div class="left-box">
      <div class="wrong-left-top">
        <div>
          <p v-if="userInfo.questionCount">{{ userInfo.questionCount }}</p>
          <p v-else>-</p>
          <span>所有试题</span>
        </div>
        <div>
          <p v-if="userInfo.scoreRateAvg || userInfo.scoreRateAvg == 0">
            {{ userInfo.scoreRateAvg | setNumber }}
          </p>
          <p v-else>-</p>
          <span>平均得分率</span>
        </div>
      </div>
      <el-table :data="userInfo.questionTypeList" border style="width: 100%">
        <el-table-column
          prop="questionType"
          label="题型"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="questionCount"
          label="数量"
          align="center"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="scoreRate"
          align="center"
          label="得分率"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.scoreRate | setNumber }}
          </template></el-table-column
        >
      </el-table>
    </div>
    <wrongListBox
      v-if="comBoxType"
      ref="wrongListBox"
      :child-obj="childObj"
      :exam-list="examList"
      :question-type-list="questionTypeList"
      :exam-paper-ids="examPaperIds"
      @setAllList="setAllList"
      @setIds="setIds"
    ></wrongListBox>
    <el-dialog title="错题一键导出" :visible.sync="dialogVisible" width="600px">
      <!-- <div>
        <div>根据当前筛选条件，共有{{ questionAll }}道试题</div>
      </div> -->
      <el-form label-width="110px">
        <div style="margin-bottom: 22px">
          根据当前筛选条件，共有{{ questionAll }}道试题
        </div>
        <el-form-item label="试题导出数量">
          <el-input v-model.number="exportData.questionNumber"></el-input>
        </el-form-item>
        <el-form-item label="附带答案">
          <el-checkbox
            v-model="exportData.addAnswer"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </el-form-item>
        <el-form-item v-if="exportData.addAnswer === 1" label="答案位置">
          <el-radio v-model="exportData.answerPosition" :label="1"
            >每道试题后面</el-radio
          >
          <el-radio v-model="exportData.answerPosition" :label="2"
            >最后</el-radio
          >
        </el-form-item>
        <el-form-item label="试题类型">
          <el-checkbox-group v-model="exportData.questionType">
            <el-checkbox :label="1">原题</el-checkbox>
            <el-checkbox :label="2">变式训练题</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="文档格式">
          <el-radio v-model="exportData.fileType" :label="1"
            >Word 文档</el-radio
          >
          <el-radio v-model="exportData.fileType" :label="2"
            >Word 97-2003 文档</el-radio
          >
          <el-radio v-model="exportData.fileType" :label="3">PDF 文档</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          class="btn-all"
          :loading="exportLoading"
          @click="exportQuestion"
          >导 出</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  questionInfo,
  downStudentQuestion,
} from "@/core/api/analy/gradeLearning";

import { fileDownloadByUrl } from "@/core/util/util";
import { subjectOptions, examTypeOptions } from "@/core/util/constdata";

import wrongListBox from "./wrongListBox.vue";
export default {
  name: "WrongBox",
  components: {
    wrongListBox,
  },
  filters: {
    setNumber(val) {
      // console.log(val);
      if (!val) {
        return "0.00 %";
      }
      let number = (val * 100).toFixed(2);
      return number + " %";
    },
  },
  props: {
    childObj: Object,
    examList: Array,
    examPaperIds: Array,
    subjectId: Number,
    optionYear: Array,
  },
  data() {
    return {
      userInfo: {},
      isLatestDevelopment: 0,
      loading: true,
      listLoading: true,
      btnloading: false,
      dialogVisible: false,
      comBoxType: false,
      exportLoading: false,
      questionTypeList: [],
      childData: {},
      subjectOptionsVal: 2,
      subjectOptions: subjectOptions,
      examTypeOptions: examTypeOptions,
      exportData: {
        //是否需要答案，1是需要 0 是不需要
        addAnswer: 1,
        // 答案在试卷的位置 2是所有题之后 1 每题之后
        answerPosition: 2,
        //导出的试卷类型 1 Word 文档 ，2  Word 97-2003 文档 ， 3 PDF 文档
        fileType: 1,
        //导出的试题数量（原题数量）
        questionNumber: 1,
        // 1 原题 2 变式题  （多选  ，隔开）
        questionType: [1],
      },

      questionAll: 0,
    };
  },
  watch: {
    subjectId() {
      if (this.subjectId) {
        this.subjectOptionsVal = this.subjectId;
        this.page.current = 1;
        this.getInfo();
        // this.getList();
      }
    },
    childObj: {
      immediate: true,
      handler() {
        this.copyData = Object.assign({}, this.childObj);
        this.getInfo();
      },
    },
  },
  created() {
    this.copyData = Object.assign({}, this.childObj);
    this.comBoxType = true;
    // this.getInfo();
  },
  methods: {
    getIsLast() {
      this.$refs.wrongListBox.getList();
    },
    setIds(val) {
      this.$emit("setPaperIds", val);
      // this.setTime();
    },
    setAllList(val) {
      this.questionAll = val;
    },
    // 批量导出
    exportQuestion() {
      // if(this.exportData.questionNumber<=0){
      //   return
      // }
      let r = /^[0-9]*[1-9][0-9]*$/;
      let type = r.test(this.exportData.questionNumber);
      if (!type) {
        this.$message({
          message: "输入的不是正整数",
          type: "warning",
        });
        return false;
      }

      let data = JSON.parse(JSON.stringify(this.copyData));
      let listVal = JSON.parse(JSON.stringify(this.$refs.wrongListBox.listVal));
      let page = JSON.parse(JSON.stringify(this.$refs.wrongListBox.page));
      // console.log();
      data = Object.assign(data, this.exportData, listVal, page);
      data.questionType = data.questionType.join(",");

      data.examPaperIds = this.examPaperIds.join(",");
      data.size = data.questionNumber;
      delete data.questionNumber;
      // delete data.examPaperIds;
      this.exportLoading = true;
      this.$message({
        message: "数据转换中，请稍后！",
        type: "success",
      });

      downStudentQuestion(data)
        .then((res) => {
          this.exportLoading = false;
          this.dialogVisible = false;
          let name = "";
          if (!this.copyData.studentId) {
            name =
              this.optionYear.filter((i) => i.value == this.copyData.gradeId)[0]
                .label + "级_";
            if (this.copyData.classNum) {
              name = name + this.copyData.classNum + "班_";
            }
            name =
              name +
              this.subjectOptions.filter(
                (i) => i.value == this.copyData.subjectId
              )[0].label +
              "的错题篮";
          }
          if (!name) {
            name = res.data.data.name;
          }
          if (res.data.data) {
            fileDownloadByUrl(res.data.data.url, name);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },

    setComType() {
      this.loading = true;
      this.page.current = 1;
      this.$emit("setId", this.subjectOptionsVal);
      this.getInfo();
      // this.getList();
    },
    questionExport() {
      this.dialogVisible = true;
      this.exportData.questionNumber = Number(this.questionAll);
    },

    getInfo() {
      let data = JSON.parse(JSON.stringify(this.copyData));
      // console.log(data);
      // data.examPaperIds = data.examPaperIds.join(",");
      data.scoreRateMin = 0;
      data.scoreRateMax = 1;
      this.loading = true;
      this.listLoading = true;
      questionInfo(data)
        .then((res) => {
          this.loading = false;
          this.listLoading = false;
          if (res.data.data) {
            this.userInfo = res.data.data;
            this.questionTypeList = this.userInfo.questionTypeList;
            this.userInfo.examList.map((index) => {
              let str = this.examTypeOptions.filter(
                (item) => index.type == item.value
              )[0].label;
              index.examName =
                index.examDate + " " + index.examName + "（" + str + "）";
              // examTypeOptions
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.listLoading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.wrong-box {
  .el-button--text {
    color: #2474ed;
  }
  .set-input {
    width: 87px;
    //  .el-input .el-select__caret
    input {
      background-color: #f5f5f5;
      line-height: 35px !important;
      height: 35px !important;
      text-align: right;
      border: none;
      color: $primary-color;
    }
    .el-icon-arrow-up:before {
      // .el-icon-arrow-up:before
      content: "";
    }
    // .el-input__icon:after {
    //   content: "";
    // }
    .el-input {
      .el-select__caret {
        line-height: 35px;
        height: 35px;
      }
    }
  }
  .morelist {
    // overflow-x: scroll;
    width: 120px;
    // &::-webkit-scrollbar {
    //   width: 0;
    //   height: 0;
    // }

    // ///*<!--修改 滑块 -->*/
    // &::-webkit-scrollbar-thumb {
    //   background-color: rgba(0, 0, 0, 0.25);
    // }
    input:-ms-input-placeholder {
      color: #2474ed !important;
    }
    input::placeholder {
      color: #2474ed;
    }
    .el-select__tags {
      flex-wrap: unset;
      .el-tag {
        color: #2474ed;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.wrong-box {
  border: 1px solid #dddddd;
  padding: 80px 24px 24px;
  position: relative;
  margin: 24px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .page-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    margin-bottom: 20px;
    .el-pagination {
      padding-right: 0px;
    }
  }

  .left-box {
    width: 280px;
    border: 1px solid #dddddd;
    padding: 12px;
    .wrong-left-top {
      display: flex;
      justify-content: space-between;
      text-align: center;
      padding: 0 12px;
      position: relative;
      margin-bottom: 24px;
      &::after {
        position: absolute;
        width: 1px;
        height: 80%;
        content: "";
        left: 50%;
        top: 10%;
        background-color: #dddddd;
      }
      // div {
      //   &:last-child {
      //     border-left: 1px solid #dddddd;
      //   }
      // // }

      p {
        font-size: 20px;
      }
      span {
        color: #dddddd;
      }
    }
  }
  .wrong-title {
    position: absolute;
    left: 0;
    top: 24px;
    padding: 0 24px;
    color: $primary-color;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .el-select {
      width: 180px;
      margin-right: 12px;
    }
    .el-button {
      padding: 6px 12px;
    }
    &::after {
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: $primary-color;
      content: "";
      left: 0;
      top: 0;
    }
  }
}
</style>
