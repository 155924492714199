<template>
  <div v-loading="loading" class="topMsg">
    <p v-if="childObj.timeRange || childObj.timeRange == 0">
      在{{ childObj.timeRange | setName(optionsTime) }}考试内，<template
        v-if="childObj.studentId"
        >{{ childObj.username }}</template
      ><template v-else
        >{{ childObj.gradeId | setName(optionYear) }}级<template
          v-if="childObj.classNum"
          >{{ childObj.classNum }}班</template
        ><template v-if="!childObj.studentId">
          {{ childObj.subjectId | setName(subjectOptions) }}
        </template></template
      >考试共
      {{ topMsg.examCount }}
      场，共考核知识点
      {{ topMsg.totalKnowledgeCount }}
      个， 薄弱知识点
      {{ topMsg.weakKnowledgeCount }}
      个，
      <template v-if="childObj.classNum"
        ><template v-if="childObj.studentId">个人</template
        ><template v-else>所有</template></template
      ><template v-else>所有</template>错题
      {{ topMsg.commonQuestionCount }}
      题。
    </p>
    <!-- {{ childObj.studentId }} 111111111 -->
    <template v-if="!childObj.studentId">
      <p>其中建议关注知识点有：</p>
      <!-- <p
      v-if="
        topMsg.lowScoreRateKnowledgeNames &&
          topMsg.lowScoreRateKnowledgeNames.length > 0

      "
    >
      {{ topMsg.lowScoreRateKnowledgeNames }}
      ：<template v-if="childObj.classNum && !childObj.studentId">班级</template
      ><template v-else>年级</template>得分率较低；
    </p> -->
      <p
        v-if="topMsg.weakKnowledgeNames && topMsg.weakKnowledgeNames.length > 0"
      >
        {{ topMsg.weakKnowledgeNames }}
        ：未掌握<template v-if="childObj.classNum">人</template
        ><template v-else>班级</template>数较多；
      </p>
      <p
        v-if="topMsg.freqKnowledgeNames && topMsg.freqKnowledgeNames.length > 0"
      >
        {{ topMsg.freqKnowledgeNames }}：<template v-if="childObj.classNum"
          >班级</template
        ><template v-else>年级</template>考频较高。
      </p>
    </template>
  </div>
</template>

<script>
import { subjectOptions, optionsTime } from "@/core/util/constdata";
import { fetchList } from "@/core/api/analy/gradeLearning";
export default {
  name: "TopMsg",
  props: {
    childObj: Object,
    optionYear: Array,
    optionsClass: Array,
  },
  data() {
    return {
      topMsg: {},
      subjectOptions: subjectOptions,
      optionsTime: optionsTime,
      loading: false,
    };
  },
  watch: {
    childObj: {
      immediate: true,
      handler() {
        this.getMsg();
      },
    },
  },
  created() {
    // this.getMsg();
  },
  methods: {
    getMsg() {
      this.loading = true;
      fetchList(this.childObj)
        .then((res) => {
          this.loading = false;
          if (res.data.data) {
            this.topMsg = res.data.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
// .topMsg{
p {
  margin-bottom: 6px;
}
// }
</style>
